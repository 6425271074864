import React, { FunctionComponent } from 'react';
import { FocusStyleManager } from '@blueprintjs/core';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import LoginPage from './pages/login';

import './styles/base.scss';
import UserContext, { UserData } from './context/UserContext';
import HomePage from './pages/home';
import ServersPage from './pages/servers';
import ServerLogsPage from './pages/server-logs';
import BansPage from './pages/bans';

FocusStyleManager.onlyShowFocusOnTabs();

const LoginPageWrapper: FunctionComponent = () => {
  const history = useHistory();
  const msg = new URLSearchParams(history.location.search).get('login_error');
  const component = msg === null ?  <LoginPage /> : <LoginPage errorMessage={msg}/>;
  history.replace('/');
  return component;
};

(async () => {
  const response = await fetch('/user');
  const userData = response.ok ? await response.json() as UserData : undefined;

  const App = (
    <UserContext.Provider value={userData}>
      <BrowserRouter>
        <Switch>
          <Route exact path='/'>
            {userData ? <HomePage /> : <LoginPageWrapper />}
          </Route>
          <Route exact path='/servers'>
            <ServersPage />
          </Route>
          <Route path='/logs/:id'>
            <ServerLogsPage />
          </Route>  
          <Route path='/bans'>
            <BansPage />
          </Route>  
          <Route path='*'><h2>404</h2></Route>
        </Switch>
      </BrowserRouter>
    </UserContext.Provider>
  );
  
  ReactDOM.render(App, document.getElementById('root'));  
})();

