import React, { FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react';
import UserContext from '../context/UserContext';
import Header from '../components/header';
import { Event, GameServer, GameServerView } from '@vladmarica/em2-web-types';
import { Card, H3, H4, Icon, IconName, Intent, Pre } from '@blueprintjs/core';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { Duration } from 'luxon';

import styles from './server-logs.module.scss';

const ServerLogsPage: FunctionComponent = () => {
  const user = useContext(UserContext);

  if (!user) {
    return <p>You are not logged in</p>;
  }

  const { id } = useParams<{ id: string}>();
  const now = Date.now();

  const [server, setServer] = useState<GameServer>();
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    const serverResponse = fetch(`/gateway/server/${id}`);
    const eventsResponse = fetch(`/gateway/server/${id}/events`);

    Promise.all([serverResponse, eventsResponse])
      .then((results) => Promise.all(results.map(res => res.json())))
      .then((results) => {
        setServer(results[0]);
        setEvents(results[1].events);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <>
      <Header />
      <div className={cn(styles['server-logs-container'])}>
        {server && (() => <H3>{server.id}</H3>)()}
        {server && (() => <span>{server.current_players} players online</span>)()}

        <Pre className={cn('bp3-dark', styles['code-block'])}>
          <code className={cn(styles['code-block'])}>
            {events.map(event => {
              return <EventLine startTime={server?.start_time || 0} event={event} key={event.id}/>;
            })}
          </code>
        </Pre>
      </div>
    </>
  );
};

const EventLine: FunctionComponent<{ startTime: number, event: Event }> = (props) => {
  const className = styles[`event-${props.event.type.toLowerCase()}`];
  const relativeTime = Duration.fromMillis((props.event.time - props.startTime) * 1000);

  return (
    <>
      <span className={className}>
      [{relativeTime.toFormat('hh:mm:ss')}] {props.event.message}
      </span>
      {props.event.data?.stacktrace && <span className={styles['stacktrace']}>{props.event.data?.stacktrace}</span>}
    </>
  );
};

export default ServerLogsPage;