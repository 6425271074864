import React, { FunctionComponent, useContext } from 'react';
import UserContext from '../context/UserContext';
import Header from '../components/header';

const HomePage: FunctionComponent = () => {
  const user = useContext(UserContext);

  if (!user) {
    return <p>You are not logged in</p>;
  }

  return (
    <Header />
  );
};

export default HomePage;