import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import UserContext from '../context/UserContext';
import Header from '../components/header';
import { BanData, BanLogEntry } from '@vladmarica/em2-web-types';
import { Button, Classes, Dialog, H3, H4, H5, HTMLSelect, IDialogProps, InputGroup, Tag } from '@blueprintjs/core';
import cn from 'classnames';
import { DateTime } from 'luxon';
import RobloxUtil from '../roblox-util';

import styles from './bans.module.scss';
import { createReactRef } from '@blueprintjs/core/lib/esm/common/utils';

interface RobloxPlayer {
  id: number;
  username: string;
}

const BansPage: FunctionComponent = () => {
  const user = useContext(UserContext);

  if (!user) {
    return <p>You are not logged in</p>;
  }

  const [bans, setBans] = useState<BanData[]>([]);
  const [banLogEntries, setBanLogEntries] = useState<BanLogEntry[]>([]);
  const [isBanDialogOpen, setIsBanDialogOpen] = useState(false);
  const [isUnbanDialogOpen, setIsUnbanDialogOpen] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<RobloxPlayer>({ id: 0, username: '' });

  useEffect(() => {
    const bansResponse = fetch('/gateway/bans');
    const banLogResponse = fetch('/gateway/bans/log');

    Promise.all([bansResponse, banLogResponse])
      .then((results) => Promise.all(results.map(result => result.json())))
      .then((results) => {
        setBans(results[0].bans);
        setBanLogEntries((results[1].log as Array<BanLogEntry>).sort((a, b) => b.time - a.time));
      })
      .catch((err) => console.error(err));
  }, []);

  const onBanConfirm = async (player: RobloxUserInfo, reason: string, description: string) => {
    setIsBanDialogOpen(false);

    const response = await fetch('/gateway/bans', {
      method: 'POST',
      body: JSON.stringify({
        roblox_id: player.id,
        username: player.name,
        reason: reason,
        description: description
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    console.log(`Ban request returned code ${response.status}`);
    if (response.status === 200) {
      window.location.reload();
    }
  }

  const onUnbanConfirm = async (player: RobloxPlayer, reason: string) => {
    setIsUnbanDialogOpen(false);
    console.log('Unbanning ' + player.username + 'because ' + reason);

    const response = await fetch('/gateway/bans/unban', {
      method: 'POST',
      body: JSON.stringify({
        roblox_id: player.id,
        unban_reason: reason
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    console.log(`Unban request returned code ${response.status}`);
    if (response.status === 200) {
      window.location.reload();
    }
  };

  return (
    <>
      <Header />
      <div className={cn(styles['ban-list-container'])}>
        <div className={cn(styles['ban-list-header-container'])}>
          <H3>Banned Players</H3>
          <Button intent='danger' icon='blocked-person' fill={false} onClick={() => setIsBanDialogOpen(true)}>
            Ban a player
          </Button>
        </div>
        <table className={
          cn('bp3-html-table', 'bp3-html-table-striped', 'bp3-html-table-condensed', 'bp3-dark', styles['ban-table'])}>
          <thead>
            <tr>
              <td>Player</td>
              <td>Reason</td>
              <td>Note</td>
              <td className={styles['nostretch']}>Banned by</td>
              <td>Time</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {bans.map((banData) => (
              <tr key={banData.roblox_id}>
                <td className={styles['nostretch']}>
                  <RobloxLink player={{ id: banData.roblox_id, username: banData.username }} />
                </td>
                <td className={styles['nostretch']}>{banData.reason}</td>
                <td>{banData.description}</td>
                <td className={styles['nostretch']}>
                  <RobloxLink player={{ id: banData.banned_by_roblox_id, username: banData.banned_by_username }} />
                </td>
                <td className={styles['nostretch']}>
                  {DateTime.fromSeconds(banData.time).toRelative()}
                </td>
                <td className={styles['nostretch']}>
                  <Button intent='primary' onClick={() => {
                    setSelectedPlayer({ id: banData.roblox_id, username: banData.username }),
                      setIsUnbanDialogOpen(true);
                  }}>Unban</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <H3>Ban Log</H3>
        <table className={
          cn('bp3-html-table', 'bp3-html-table-striped', 'bp3-html-table-condensed', 'bp3-dark', styles['ban-table'])}>
          <thead>
            <tr>
              <td>Timestamp</td>
              <td>Type</td>
              <td>Message</td>
              <td>Reason</td>
              <td>Note</td>
            </tr>
          </thead>
          <tbody>
            {banLogEntries.map((logEntry) => <LogEntryComponent key={logEntry.id} entry={logEntry} />)}
          </tbody>
        </table>
        <UnbanDialog player={selectedPlayer} isOpen={isUnbanDialogOpen}
          onClose={() => setIsUnbanDialogOpen(false)} onConfirm={onUnbanConfirm} />
        <BanDialog isOpen={isBanDialogOpen}
          onClose={() => setIsBanDialogOpen(false)} onConfirm={onBanConfirm} />
      </div>
    </>
  );
};

const LogEntryComponent: FunctionComponent<{ entry: BanLogEntry }> = (props) => {
  const { entry } = { ...props };
  return (
    <tr>
      <td className={styles['nostretch']}>{DateTime.fromSeconds(entry.time).toRelative()}</td>
      <td className={styles['nostretch']}>
        <Tag className={styles['log-type-tag']} intent={entry.type === 'Ban' ? 'danger' : 'success'}>{entry.type}</Tag>
      </td>
      <td className={styles['nostretch']}>
        <RobloxLink player={{ id: entry.roblox_id, username: entry.username }} />
          &nbsp;was {entry.type === 'Ban' ? 'banned' : 'unbanned'} by&nbsp;
        <RobloxLink player={{ id: entry.banned_by_roblox_id, username: entry.banned_by_username }} />
      </td>
      <td className={styles['nostretch']}>{entry.reason}</td>
      <td>{entry.description}</td>
    </tr>
  );
};

const RobloxLink: FunctionComponent<{ player: RobloxPlayer }> = (props) => (
  <a href={`https://www.roblox.com/users/${props.player.id}/profile`}>{props.player.username || 'Unknown'}</a>
);

interface RobloxUserInfo {
  id: number;
  name: string;
  displayName: string;
}

type BanDialogProps = {
  onConfirm: (player: RobloxUserInfo, reason: string, description: string) => void;
}

const BanDialog: FunctionComponent<IDialogProps & BanDialogProps> = (props) => {
  const [userId, setUserId] = useState(0);
  const [userInfo, setUserInfo] = useState<RobloxUserInfo>();
  const [description, setDescription] = useState<string>();
  const reasonSelectRef = createReactRef<HTMLSelectElement>();

  const onUserIdChanged = async function(newUserId: number) {
    if (isNaN(newUserId) || newUserId < 0) {
      newUserId = 0;
    }

    setUserId(newUserId);

    const response = await fetch(`/roblox/user/${newUserId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    setUserInfo(response.status === 200 ? await response.json() : undefined);    
  }

  return (
    <Dialog
      icon='info-sign'
      title='Ban a player'
      className={cn('bp3-dark')}
      {...props}>
      <div className={cn('bp3-dark', Classes.DIALOG_BODY)}>
        <div className={styles['ban-roblox-id-container']}>
          <div className={styles['ban-roblox-id-form']}>
            <H5>Enter the player's Roblox user ID:</H5>
            <InputGroup type='number' placeholder={'Roblox user ID'}
              onChange={(evt) => onUserIdChanged(parseInt(evt.target.value))} />
          </div>
          <div className={styles['ban-roblox-id-user-info']}>
            {userInfo !== undefined &&
             <>
                <img src={RobloxUtil.getUserAvatarUrl(userId, 100)}></img>
                <b>{userInfo.displayName}</b>
                <span>@{userInfo.name}</span>
             </>
            }
          </div>
        </div>
       
        <br/>
        <p>Reason</p>
        <HTMLSelect elementRef={reasonSelectRef}>
          <option selected value="Exploiting">Exploiting</option>
          <option value="Other">Other</option>
        </HTMLSelect>

        <br/><br/>
        <InputGroup type='string' placeholder={'Enter a short explanation...'}
          onChange={(evt) => setDescription(evt.target.value)} />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button intent='danger' disabled={userInfo === undefined || description === undefined || description === ''}
            onClick={() => props.onConfirm(
              userInfo!,
              reasonSelectRef.current!.options[reasonSelectRef.current!.selectedIndex].value,
              description!)}>
            Ban {userInfo !== undefined ? userInfo.name : ''}</Button>
        </div>
      </div>
    </Dialog>
  );
};

type UnbanDialogProps = {
  player: RobloxPlayer;
  onConfirm: (player: RobloxPlayer, reason: string) => void;
}

const UnbanDialog: FunctionComponent<UnbanDialogProps & IDialogProps> = (props) => {
  const [reason, setReason] = useState('');

  const onClose = (event: React.SyntheticEvent<HTMLElement, Event>) => {
    setReason('');
    if (props.onClose) {
      props.onClose(event);
    }
  };

  const onConfirmClick = () => {
    props.onConfirm(props.player, reason);
    setReason('');
  };

  return (
    <Dialog
      icon='info-sign'
      title={'Unban ' + props.player.username + '?'}
      className={cn('bp3-dark')}
      {...props}
      onClose={onClose}>
      <div className={cn('bp3-dark', Classes.DIALOG_BODY)}>
        <p>Are you sure you want to unban <b>{props.player.username}</b>?</p>
        <InputGroup placeholder={'Enter unban reason...'}
          onChange={(evt) => setReason(evt.target.value)} />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose}>Cancel</Button>
          <Button intent='success' disabled={reason.trim().length === 0}
            onClick={onConfirmClick}>Confirm</Button>
        </div>
      </div>
    </Dialog>
  );
};


export default BansPage;
