import React, { FunctionComponent } from 'react';
import { Button, Callout } from '@blueprintjs/core';
import IconDiscord from '../res/discord.svg';
import cn from 'classnames';

import styles from './login.module.scss';

interface Props {
  errorMessage?: string;
}

const LoginPage: FunctionComponent<Props> = (props) => {
  const iconWrapper = (<span className="bp3-icon">
    <IconDiscord width={20} height={20} />
  </span>);

  return (
    <div className={cn(styles['container'], 'bp3-dark')}>
      {props.errorMessage && <Callout intent='danger' title='Login error'>
        {props.errorMessage}
      </Callout>}
      <div className={cn(styles['card'], 'bp3-dark')}>
        <h1>Epic Mining 2 Web Services</h1>
        <a href="/oauth/login">
          <Button className='discord-button' icon={iconWrapper}>
            Log in with Discord
          </Button>
        </a>
      </div>
    </div>);
};

export default LoginPage;