import React, { FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react';
import UserContext from '../context/UserContext';
import Header from '../components/header';
import { GameServerView } from '@vladmarica/em2-web-types';
import { Card, H4, Icon, IconName, Intent } from '@blueprintjs/core';
import cn from 'classnames';
import { Duration } from 'luxon';
import { IconNames } from '@blueprintjs/icons';
import styles from './servers.module.scss';
import { Link } from 'react-router-dom';


const ServersPage: FunctionComponent = () => {
  const user = useContext(UserContext);

  if (!user) {
    return <p>You are not logged in</p>;
  }

  const [liveGameServers, setLiveGameServers] = useState<GameServerView[]>([]);

  useEffect(() => {
    fetch('/gateway/server/live')
      .then(async (response) => setLiveGameServers(await response.json()))
      .catch((err) => console.error(err));
  }, []);

  const now = Date.now();

  return (
    <>
      <Header />
      <div className={cn(styles['server-list-container'])}>
        <H4>{liveGameServers.length} live servers</H4>
        {liveGameServers.map((server) => <GameServerViewCard key={server.id} server={server} now={now} />)}
      </div>
    </>
  );
};

const GameServerViewCard: FunctionComponent<{ server: GameServerView, now: number }> = (props) => {
  const uptime = Duration.fromMillis(props.now - (props.server.start_time * 1000));
  return (
    <Link to={`/logs/${props.server.id}`}>
      <Card className={cn('bp3-dark', styles['server-card'])} interactive={true}>
        <div className={cn(styles['server-card-left-container'])}>
          <span className={cn(styles['server-id-label'])}>{props.server.id}</span>
          <span className={cn(styles['subitem'])}>{props.server.current_players} players online</span>
          <span className={cn(styles['subitem'])}>{uptime.toFormat('hh:mm:ss')} uptime</span>
        </div>
        <div className={cn(styles['icon-container'])}>
          {makeIcon(IconNames.ERROR, 'danger', props.server.errors)}
          {makeIcon(IconNames.ERROR, 'warning', props.server.warnings)}
          {makeIcon(IconNames.ERROR, 'primary', props.server.importants)}
        </div>
      </Card>
    </Link>
  );
};

function makeIcon(iconName: IconName, intent: Intent, count: number): ReactElement | null{
  if (count === 0) {
    return null;
  }

  return (
    <span className={styles['icon-wrapper']}>
      <Icon icon={iconName} intent={intent} /> {count}
    </span>
  );
}

export default ServersPage;