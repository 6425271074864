import React from 'react';

export interface UserData {
  user_id: string;
  username: string;
  avatar?: string;
}

const UserContext = React.createContext<UserData | undefined>(undefined);
export default UserContext;
