import React, { FunctionComponent, useContext } from 'react';
import cn from 'classnames';
import UserContext from '../context/UserContext';
import { Button, Navbar, Alignment, Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { Link } from 'react-router-dom';

import styles from './header.module.scss';

const Header: FunctionComponent = () => {
  const user = useContext(UserContext);

  return (
    <Navbar className='bp3-dark'>
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading>Epic Mining 2 Web Services</Navbar.Heading>
        <Navbar.Divider />
        <Link to='/'><Button className="bp3-minimal" icon="home" text="Home"/></Link>
        <Link to='/servers'><Button className="bp3-minimal" icon="globe" text="Servers"/></Link>
        <Link to='/bans'><Button className="bp3-minimal" icon="blocked-person" text="Bans"/></Link>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <img
          className={cn(styles['avatar'])}
          height={26}
          width={26}
          src={`https://cdn.discordapp.com/avatars/${user?.user_id}/${user?.avatar}.png?size=32`} />
        <span className={cn(styles['username-label'])}>{user?.username}</span>
        <Popover2
          minimal={true}
          placement="bottom"
          interactionKind="click"
          content={
            <Menu className={cn(styles['menu'])}>
              <MenuItem text="Logout" onClick={async () => {
                await fetch('/logout', { method: 'POST' });
                window.location.reload();
              }}/>
            </Menu>
          }>
          <Button className="bp3-minimal" icon='arrow-down' />
        </Popover2>
      </Navbar.Group>
    </Navbar>
  );
};

export default Header;
